import React, {useEffect, useState} from 'react';
import {activationUser} from '../../components/Auth/Utils';
import withLocation from '../../components/shared/withLocation';
import Layout from "../../components/shared/layout";
import SEO from "../../components/SEO/SEOPage";
import Img from "gatsby-image";
import ButtonLink from "../../components/shared/ButtonLink";
import {graphql} from "gatsby";
import styled from "@emotion/styled";
import {wrapper1224} from "../../utils/style";

const Wrapper = styled('div')`
  ${wrapper1224}
  margin: 30px auto;
  p {
    margin-bottom: 1rem;
  }
`;

const ActivationUserTemplate = ({pageContext, data, search, location, navigate }) => {

  const homePageUrl = '/';
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [activationError, setActivationError] = useState(false);

  if(search && (search.action === 'activation') && search.email && search.code) {
    activationUser('', search, setRedirectToHome, setActivationError)
  }

  useEffect(() => {
    if(redirectToHome){
      navigate(homePageUrl);
    }
    if(search && (search.action !== 'activation')) {
      setTimeout(() => {navigate(homePageUrl)}, 3000); // 3 SEC
    }
  }, [redirectToHome]);

  return (
    <React.Fragment>
    {activationError && <Layout>
      <SEO title="Account Activation" description="Info on your Oppa888 account activation"/>
      <Wrapper>
        <h1>Activation Notification:</h1>
        <p>Maaf, tautan untuk aktivasi salah atau sudah pernah digunakan untuk aktivasi sebelumnya.</p>
        <ButtonLink to="/" text="Klik disini untuk kembali ke halaman beranda" ariaText="Klik disini untuk kembali ke halaman beranda"/>
      </Wrapper>
    </Layout>}

    {!activationError && !redirectToHome && <Layout>
      <Wrapper>
        <div className="flex">
          <div>
            <h1>Activating account...</h1>
          </div>
        </div>
      </Wrapper>
    </Layout>}

    </React.Fragment>
  );
};
export default withLocation(ActivationUserTemplate);

// Set here the ID of the home page.
export const pageQuery = graphql`
    query {
        missing: file(relativePath: { eq: "logo-gclub-casino.png" }) {
            childImageSharp {
                fluid(maxWidth: 480) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
